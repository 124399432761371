import { handleEditorStyles } from './editor/styles'
import Utils from './utils'
import consumer from '../channels/consumer'
import { DOMEmit } from './dom_utils'
import type {
  IQuestionnaireStore,
  QStoreContext,
  SmartStore
} from './QuestionnaireUtils'
import {
  getSaveEntryDocumentID,
  uniqueSharedRepeaters
} from './template_packs/save_entry_utils'
import { useTemplateVersionStore } from '@stores/generic/templateVersion.store'
import { getActivePinia } from 'pinia'
import { compareValues } from './compare_values/compare_values'
import { getStateFromBoolean } from './questionnaire/sidekiq_states'
import NumberUtils from './number_utils/number_utils'
import { getValueAndModificator } from './template_packs/save_entry_utils'

const axios = Utils.axios

interface IChangedPartyNames {
  newPartyName: string
  oldPartyName?: string
}

export const UNALLOCATED_QUESTIONNAIRE = 'Unallocated'

export const findChangedParty = (
  oldPartiesNames: string[],
  newPartiesNames: string[]
): string | null | IChangedPartyNames | undefined => {
  if (oldPartiesNames.length < newPartiesNames.length) {
    const addedPartyName = newPartiesNames.find(
      (partyName) => oldPartiesNames.indexOf(partyName) === -1
    )
    return addedPartyName
  } else {
    const newPartyName = newPartiesNames.find(
      (partyName) => oldPartiesNames.indexOf(partyName) === -1
    )
    if (newPartyName == null) return null
    const oldPartyName = oldPartiesNames.find(
      (partyName) => newPartiesNames.indexOf(partyName) === -1
    )
    return { newPartyName, oldPartyName }
  }
}

export const dsSplitter = '#='

export function deleteQuestionnaire(
  store: QStoreContext,
  partyToRealocate: string
) {
  store.commit('DELETE_QUESTIONNAIRE', store.state.selected_party)
  if (partyToRealocate) {
    const questions = store.state.questions.filter(
      (q: Backend.Questionnaire.IQuestion) =>
        q.party === store.state.selected_party
    )
    questions.forEach((q) => {
      store.commit('REPLACE_QUESTION', {
        oldQuestion: q,
        newQuestion: { ...q, party: partyToRealocate }
      })
    })
  }
}

export const saveEntry = (
  {
    state,
    dispatch,
    commit
  }: { state: IQuestionnaireStore; dispatch: any; commit: any },
  att: string,
  value: string | string[],
  succesion: number
) => {
  const question = state.questions.find(
    (q: Backend.Questionnaire.IQuestion) => q.att === att
  )
  if (!question) {
    dispatch('save_entry', { att, start: false, succesion })
    return
  }
  const data: Record<string, any> = {
    value,
    type: question.type,
    entry_name: window.AvvParser.unicode(att),
    send_agreement: state.show_editor,
    id: state.document_id
  }

  const repeaterID = question.opts['repeater-id']
  const isCurrency = question.type === 'currency'
  const isPhone = question.type === 'phone'

  if (state.created_from_pack) {
    data.id = getSaveEntryDocumentID(
      AvvStore.state.documents_by_attributes,
      AvvStore.state.loopIdsByDoc,
      state.document_id,
      att,
      repeaterID
    )
  }

  if (repeaterID) {
    data.isRepeater = true
    data.repeater = repeaterID
    data.succession = succesion
    if (Array.isArray(data.value)) data.value = data.value[data.succession]
  }

  const valueAndModificator = getValueAndModificator(
    data.value as string,
    question
  )
  data.value = valueAndModificator.value
  data.modificator = valueAndModificator.modificator

  const url = AvvStore.state.save_entry_url || '/documents/save_entry'
  const previouslySavedValue = repeaterID ? state.last_saved_entries[att]?.[succesion] : state.last_saved_entries[att]
  const isReset = typeof previouslySavedValue == 'undefined' && data.value == ''
  if (
    compareValues(previouslySavedValue, data.value, 'dq') &&
    !isCurrency &&
    !isPhone &&
    !isReset
  ) {
    dispatch('save_entry', {
      att,
      start: false,
      succesion
    })
    return
  }
  void axios
    .post<{
      questionnaire_data: Backend.Questionnaire.IQuestionnaireData
      questionnaire_locked: boolean
    }>(url, data)
    .then(async (result) => {
      await dispatch('save_entry', { att, start: false, succesion })
      commit('SET_QUESTIONNAIRE_DATA', result.data.questionnaire_data)
      if ('questionnaire_locked' in result.data)
        commit('SET_SIDEKIQ_STATUS', {
          sidekiq_status: getStateFromBoolean(result.data.questionnaire_locked),
          immediate: true
        })
      commit('ADD_LAST_SAVED_ENTRY', {
        att,
        succesion,
        value: data.value
      })
      DOMEmit('agreement_html:update', {
        html: result.data.agreement_html,
        documentID: data.id
      })
      if (!AvvStore.state.is_doc_negotiable && !AvvStore.state.is_forms) {
        const wrapper = document.querySelector<HTMLElement>(
          `#q-editor-${data.id} .avv-container`
        )
        if (!wrapper) return
        handleEditorStyles(useTemplateVersionStore(getActivePinia()), wrapper)
      }
      return
    })
}

export const getCurrentQuestionnaireData = async (): Promise<{
  valid_conditions: Record<string, number[]>
  loop_counts: Record<string, number>
}> => {
  const document_id = AvvStore.state.document_id
  const url =
    AvvStore.state.questionnaire_data_url ||
    `/documents/${document_id}/questionnaire_data`
  const response = await axios.get(url)
  return response.data.questionnaire_data
}

export const isFromRepeater = (q, repeater) => {
  if (!repeater || !q) return false
  return ['repeater-id', 'repeater-master-id'].some(
    (key) => q.opts[key] === repeater
  )
}

const shouldMerge = (q1, q2, store) => {
  if (q1.opts['repeater-merge'] || q2.opts['repeater-merge']) return true
  const masterID =
    q1.opts['repeater-master-id'] ?? q2.opts['repeater-master-id']
  const masterQuestion = store.state.questions.find(
    (q) => q.opts['repeater-id'] === masterID
  )
  if (masterQuestion) return masterQuestion.opts['repeater-merge']
  return false
}

export const areInSameRenderedRepeater = (q1: Backend.Questionnaire.IQuestion, q2: Backend.Questionnaire.IQuestion, store) => {
  if (shouldMerge(q1, q2, store))
    return (
      isFromRepeater(q2, q1.opts['repeater-id']) ||
      isFromRepeater(q2, q1.opts['repeater-master-id'])
    ) || (store.state.created_from_pack && uniqueSharedRepeaters(q1.opts['repeater-id']!, AvvStore.state.loopIdsByAttsFromDocuments).includes(q2.opts['repeater-id']!))
  else
    return (
      q2.opts['repeater-id'] == q1.opts['repeater-id'] ||
      uniqueSharedRepeaters(
        q2.opts['repeater-id'],
        AvvStore.state.loopIdsByAttsFromDocuments
      ).includes(q1.opts['repeater-id'])
    )
}

export const listenOnStatusChanges = (
  store: SmartStore<IQuestionnaireStore>,
  id?: string
) => {
  const documentID = id ? id : String(store.state.document_id)
  consumer.subscriptions.create(
    { channel: 'DocumentChannel', id: documentID },
    {
      received(response: { type: string; data: any }) {
        switch (response.type) {
          case 'questionnaire_locked':
            store.commit('SET_SIDEKIQ_STATUS', {
              sidekiq_status: getStateFromBoolean(response.data),
              immediate: false
            })
            break
          case 'questionnaire_data':
            store.commit('SET_QUESTIONNAIRE_DATA', response.data)
            break
          case 'question_update':
            store.commit('SET_QUESTION_OPTIONS', response.data)
            break
        }
      }
    }
  )
}

export const setLoopCounts = (
  force = false,
  stateLoopCounts: Record<string, number>,
  newLoopCounts: Record<string, number>
) => {
  if (force) return newLoopCounts
  else return Object.assign(stateLoopCounts, newLoopCounts)
}

export const parseMatch = (match: string, wrapInAst = true) => {
  const matchForParse = match.slice(1, -1)
  const superAst = Ast.parse(matchForParse, wrapInAst)
  if (!superAst) return null
  const ast = 'ast' in superAst ? superAst.ast : superAst
  return ast['Att_default'] as [string, string]
}

export const deleteAttachment = async (id: number) => {
  const documentID = AvvStore.state.document_id
  const data = await axios.delete(`/attachments/${id}.json?document_id=${documentID}`)
  if (data.status === 200) {
    AvvStore.commit('REMOVE_ATTACHMENT', id)
    avv_dialog({
      snackMessage: localizeText(
        'questionnaire.file_upload.attachment_deleted'
      ),
      snackStyle: 'success'
    })
    return data.status
  } else {
    avv_dialog({ snackMessage: 'Something went wrong', snackStyle: 'error' })
    return data.status
  }
}

export const renameAttachment = async (id: Backend.Models.Attachment['id'], file_file_name: Backend.Models.Attachment['file_file_name']) => {
  try {
    // No return value check needed as axios throws exception when the status code isnt 2XX
    await axios.put(`/attachments/${id}.json`, {
      document_id: AvvStore.state.document_id,
      file_file_name
    })

    avv_toast({ message: 'Attachment was successfully renamed' })

    return true
  } catch (e) {
    avv_toast({ type: 'error', message: 'Something went wrong' })

    return false
  }
}

export const partyCheck = (attachment: Backend.Models.Attachment) => {
  const creatorUserID = attachment.user_id
  const documentUsers = Object.values(AvvStore.state.users)
  const creatorData = documentUsers.find(
    (user) => user.user_id == creatorUserID
  )
  const creatorParty = creatorData ? creatorData.party_type : null
  const curentUserParty = AvvStore.state.active_participant?.party_type
  if (!curentUserParty || !creatorParty) {
    return false
  }
  return creatorParty == curentUserParty
}
