import { getObjectKeys } from '@avvoka/shared'
import { dsSplit } from '../questionnaire/types/datasheets/reusable'

export const currencySplitter = '_avvCurrencySplit_'

export const getSaveEntryDocumentID = (
  documentsByAttributes: Record<string, number[]>,
  loopIdsByDoc: Record<string, number[]>,
  defaultDocumentID: number,
  att: string,
  repeaterID: string | undefined
) => {
  let documentID
  const attDocumentIds = documentsByAttributes[att]
  if (repeaterID) {
    const loopDocumentIds = loopIdsByDoc[repeaterID]
    if (!loopDocumentIds)
      throw new Error(`No loop document ids found for repeater ${repeaterID}`)
    const loopDocumentId = attDocumentIds
      ? loopDocumentIds.find((id) => attDocumentIds.includes(id))
      : loopDocumentIds[0]
    documentID = loopDocumentId
  } else {
    documentID = attDocumentIds ? attDocumentIds[0] : defaultDocumentID
  }
  return documentID ?? defaultDocumentID
}

export const uniqueSharedRepeaters = (
  repeater: string,
  loopIdsByAttsFromDocuments: Record<string, string[]>
) => {
  if (!loopIdsByAttsFromDocuments) return []
  const sharedRepeaters = Object.values(loopIdsByAttsFromDocuments).reduce(
    (acc, val) => {
      if (val.includes(repeater)) acc.push(...val)
      return acc
    },
    []
  )
  return [...new Set(sharedRepeaters)]
}

export const setIterationDocumentID = (
  loopIdsByDoc: Record<string, number[]>,
  repeaterID: string,
  defaultDocumentID: number
) => {
  const documentIDs = loopIdsByDoc[repeaterID]
  let documentID
  if (documentIDs && documentIDs.length) documentID = documentIDs[0]
  return documentID ?? defaultDocumentID
}

export const applyEntries = (
  currentAnswers: Backend.Questionnaire.Answers,
  newAnswers: Backend.Questionnaire.Answers,
  changedAttributes: string[],
  force = false
) => {
  if (force) return newAnswers
  const allAnswerKeys = getObjectKeys(newAnswers).concat(
    getObjectKeys(currentAnswers)
  )
  return allAnswerKeys.reduce((acc, key) => {
    if (changedAttributes.includes(key)) {
      acc[key] = currentAnswers[key]
    } else acc[key] = newAnswers[key]
    return acc
  }, {} as Backend.Questionnaire.Answers)
}

export const getValueAndModificator = (
  value: string | string[],
  question: Backend.Questionnaire.IQuestion
) => {
  const isCurrency = question.type === 'currency'
  const isMultiSelect = question.type === 'multi_select'
  const isDate = question.type === 'date'
  const isFileUpload = question.type === 'file_upload'
  const isDatasheets = question.type === 'datasheets'
  const isPhone = question.type === 'phone'

  const result = { value } as { value: string | null; modificator: string; }

  if ((isCurrency || isPhone) && typeof value === 'string') {
    const newValue = value.split(currencySplitter)[0] || null
    const modificator = value.split(currencySplitter)[1] || null

    const isModificatorSet = modificator && modificator !== 'undefined'

    result.value = newValue === 'null' || (!newValue && isModificatorSet) ? '' : newValue
    if (isModificatorSet) result.modificator = modificator
  }

  if (isMultiSelect && Array.isArray(value)) {
    result.value = value?.join(question.opts.defaultSeparator)
  }

  if (isFileUpload && value && Array.isArray(value)) {
    result.value = value.join(',')
  }

  if (isDate && typeof value === 'string') {
    const newValue = value.split(currencySplitter)[0] || null
    let modifier = value.split(currencySplitter)[1] || null
    if(modifier === null && question.opts.dateFormat) modifier = question.opts.dateFormat
    result.value = newValue === 'null' ? '' : newValue
    if (modifier && modifier !== 'undefined') result.modificator = modifier
  }

  if (isDatasheets && typeof value === 'string') {
    const [newValue, modificator] = dsSplit(value)
    result.value = newValue === 'null' || newValue === null ? '' : newValue
    result.modificator = modificator
  }

  return result
}