// This is a template file for creating new stores
// Replace all "Localization" with your store name, check 'Preserve Case' before replacing

import {javascript} from "@api/index";
import {getCurrentDocumentId, useDocumentStore} from "@stores/generic/document.store";
import {getCurrentTemplateVersionId} from "@stores/generic/template.store";
import {type TemplateVersionStoreType, useTemplateVersionStore} from "@stores/generic/templateVersion.store";
import {useUserStore} from "@stores/generic/user.store";
import {ensureHydrated, useHydration} from "@stores/utils";
import {defineStore, getActivePinia} from "pinia";
import {computed, ref} from "vue";

type LocalizationModel = Backend.Models.Model & {
  localized_numbers:{
    normal: { ordinal: {} },
    text: { normal: {}, ordinal: {} }
  },
}

export type LocalizationType = ReturnType<typeof createLocalizationStore>
export const allStores = ref<Array<LocalizationType>>([])

export const createLocalizationStore = (uniqueId: string | number) => {
  const localizationStore = defineStore('localization-store-' + String(uniqueId), () => {
    const hydration = useHydration<LocalizationModel>(javascript.localization)
    allStores.value.push(localizationStore as unknown as LocalizationType)

    return {
      ...hydration,
      localizedNumbers: hydration.hydratedComputed('localized_numbers'),
    }
  })
  return localizationStore
}

export const useLocalizationStore = createLocalizationStore('current')

/**
 * Resolves the current locale based on a cascading fallback strategy:
 * 1. Template Version Settings
 * 2. Document Template Version Settings
 * 3. User Settings
 *
 * @async
 * @returns {Promise<string>} The resolved locale string
 * @throws {Error} When unable to resolve any locale
 * @example
 * try {
 *   const locale = await getCurrentLocale()
 *   console.log('Current locale:', locale)
 * } catch (error) {
 *   console.error('Failed to resolve locale:', error)
 * }
 */
export const getCurrentLocale = async () => {
  const pinia = getActivePinia();
  const templateVersionStore = useTemplateVersionStore(pinia);

  const getLocaleFromTemplateVersion = async (
    templateVersionId: number,
  ): Promise<string | null> => {
    await templateVersionStore.hydrateById(templateVersionId, ['settings_for_frontend']);
    return templateVersionStore.settings.locale || null;
  };

  // Try getting locale from template version settings
  try {
    const templateVersionId = getCurrentTemplateVersionId();
    if (templateVersionId) {
      const locale = await getLocaleFromTemplateVersion(templateVersionId);
      if (locale) return locale;
    }
  } catch (_) {}


  // Try getting locale from document template version
  try {
    const documentId = getCurrentDocumentId();
    if (documentId) {
      const documentStore = useDocumentStore(pinia);
      await documentStore.hydrateById(documentId, ['template_version_id']);
      if(documentStore.templateVersionId) {
        const locale = await getLocaleFromTemplateVersion(documentStore.templateVersionId);
        if (locale) return locale;
      }
    }
  } catch (_) {}


  // Try getting locale from user settings
  try {
    const userStore = useUserStore(pinia);
    await userStore.hydrate({}, ['locale']);
    if (userStore.locale) return userStore.locale;
  } catch (_) {}

  throw new Error("Unable to obtain locale.");
}
