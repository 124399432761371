export const compareValues = (val1, val2, questionnaire_type) => {
  val1 = val1 ?? ''
  val2 = val2 ?? ''
  const compareArrays = (arr1, arr2) => {
    // compare lengths - can save a lot of time
    if (arr1.length != arr2.length) return false

    let result = true

    for (let i = 0, l = arr1.length; i < l; i++) {
      // Check if we have nested arr2s
      if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
        // recurse into the nested arr2s
        if (!compareArrays(arr1[i], arr2[i])) result = false
      } else if (arr1[i] != arr2[i]) {
        // Warning - two different object instances will never be equal: {x:20} != {x:20}
        result = false
      }
    }
    return result
  }

  if (!['dq', 'ld'].includes(questionnaire_type)) return
  if (Array.isArray(val1) && Array.isArray(val2))
    return compareArrays(val1, val2)
  else return val1 === val2
}